import React from "react";
import { FaTwitter, FaFacebook } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="footer text-center" style={{color: 'white'}}>
      © 2020 Fu{"    "}
      <a href="https://twitter.com/karuzuru">
        <FaTwitter></FaTwitter>
      </a>{" "}
      <a href="https://www.facebook.com/kamukura.izuru">
        <FaFacebook></FaFacebook>
      </a>
    </footer>
  );
};
export default Footer;
