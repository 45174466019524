import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";


const useMultiAudio = urls => {
  const [sources] = useState(
    urls.map(url => {
        console.log(url)
      return {
         
        url: process.env.PUBLIC_URL + '/voice/'+url.path,
        audio: new Audio(process.env.PUBLIC_URL + '/voice/'+url.path)
      };
    })
  );


  const [players, setPlayers] = useState(
    urls.map(url => {
      return {
        url: process.env.PUBLIC_URL + '/voice/'+url.path,
        name: url.name,
        playing: false
      };
    })
  );

  const toggle = targetIndex => () => {
    const newPlayers = [...players];
    const currentIndex = players.findIndex(p => p.playing === true);
    if (currentIndex !== -1 && currentIndex !== targetIndex) {
      newPlayers[currentIndex].playing = false;
      newPlayers[targetIndex].playing = true;
    } else if (currentIndex !== -1) {
      newPlayers[targetIndex].playing = false;
    } else {
      newPlayers[targetIndex].playing = true;
    }
    setPlayers(newPlayers);
  };

  useEffect(() => {
    sources.forEach((source, i) => {
      players[i].playing ? source.audio.play() : source.audio.load();
    });
  }, [sources, players]);

  useEffect(() => {
    sources.forEach((source, i) => {
      source.audio.addEventListener("ended", () => {
        const newPlayers = [...players];
        newPlayers[i].playing = false;
        setPlayers(newPlayers);
      });
    });
    return () => {
      sources.forEach((source, i) => {
        source.audio.removeEventListener("ended", () => {
          const newPlayers = [...players];
          newPlayers[i].playing = false;
          setPlayers(newPlayers);
        });
      });
    };
  }, []);

  return [players, toggle];
};



const MultiPlayer = ({ urls }) => {
  const [players, toggle] = useMultiAudio(urls);

  return (
    <React.Fragment>
      {players.map((player, i) => (
        <Player key={i} player={player} toggle={toggle(i)} />
      ))}
      </React.Fragment>
  );
};

const Player = ({ player, toggle}) => (
    <>
    <Button onClick={toggle} className="btn" outline color="success" >{player.playing ? player.name : player.name}</Button>{' '}
    </>
);

export default MultiPlayer;
