import React from "react";
import {
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { NavLink } from 'react-router-dom';

import { FiMoon } from "react-icons/fi";
import { MdTextFormat } from "react-icons/md";
import { Link } from "react-router-dom";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
// import logodarkicon from '';

const Header = () => {
  const showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };

  /*--------------------------------------------------------------------------------*/
  /*To open Search Bar                                                              */
  /*--------------------------------------------------------------------------------*/
  const toggleMenu = () => {
    document.getElementById("search").classList.toggle("show-search");
  };

  return (
    <header className="topbar navbarbg" data-navbarbg="skin4">
      <Navbar className="top-navbar" dark expand="md">
        <div className="navbar-header" id="logobg" data-logobg="skin4">
          {/*--------------------------------------------------------------------------------*/}
          {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
          {/*--------------------------------------------------------------------------------*/}
          <NavbarBrand href="/">
            <span className="logo-text">
              <h3 style={{ color: "white", fontFamily: "Comic Sans MS", fontSize: '25px' }}>
              🎨 Iofi Button 🎨
              </h3>
            </span>
          </NavbarBrand>
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <button
            className="btn-link nav-toggler d-block d-md-none text-white"
            onClick={() => showMobilemenu()}
          >
            <i className="ti-menu ti-close" />
          </button>
        </div>
        <Collapse className="navbarbg" navbar data-navbarbg="skin4">
          <Nav className="float-left" navbar>
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Search-box toggle                                                        */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <NavItem className="hidden-sm-down search-box">
                        
                        </NavItem> */}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Search-box toggle                                                          */}
            {/*--------------------------------------------------------------------------------*/}
          </Nav>
          <Nav className="ml-auto float-right" navbar>
            <NavItem>{/* Toogle Buttton */}</NavItem>
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Profile Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="pro-pic">
                <span>
                  <MdTextFormat></MdTextFormat>
                </span>
              </DropdownToggle>
              <DropdownMenu right className="user-dd">
                <DropdownItem>
                  <NavLink to={'/id/beranda'}><span className="hide-menu">Indonesia</span></NavLink>
                 </DropdownItem>
                <DropdownItem><NavLink to={'/en/home'}><span className="hide-menu">English</span></NavLink></DropdownItem>
                {/* <DropdownItem>日本人</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Profile Dropdown                                                           */}
            {/*--------------------------------------------------------------------------------*/}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};
export default Header;
