export const voice = [
{
  "group_name": "Klip",
  "group_title": "Klip ",
  "voice_list": [
    {
        "name": "Ketawa Jahat",
        "path": "ketawa-jahat.mp3"
    },
    {
        "name": "Makan Dong",
        "path": "makan-dong.mp3"
    }
      
  ]
},
{
  "group_name": "DLC",
  "group_title": "DLC ",
  "voice_list": [
    {
        "name": "Yandere",
        "path": "yandere.mp3"
    },
      
  ]
},
]