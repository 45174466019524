import React from "react";
import { Card, CardText, CardBody, CardTitle, Row, Col } from "reactstrap";
import { RiLiveLine } from "react-icons/ri";
import axios from 'axios'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/id';

class Jadwal extends React.Component {

  constructor() {
    super()
    this.state = {
      data_up: [],
      data_live: [],
      data_up_check: '',
      data_live_check: '',
      isLoading: false,
    };
  }
        componentDidMount() {
          this.setState({ isLoading: true });

            axios.get(`https://api.holotools.app/v1/live?channel_id=19`)
            .then(res => {

                // console.log(res.data.live)
                const data_up = res.data.upcoming;
                const data_live = res.data.live
              // console.log(data_up)

             
              this.setState({ data_up });
              this.setState({ data_live });

              if(res.data.upcoming.length == 0)
              {
                const data_up_check = 'false'
                this.setState({ data_up_check });
              }

              if(res.data.live.length == 0)
              {
                const data_live_check = 'false'
                this.setState({ data_live_check });
              }
              this.setState({ isLoading: false });
            })
  }


  live_upcoming(data, key)
  {

    var date = new Date(data.live_schedule).toLocaleString( { timeZone: 'Asia/Bangkok' });
    // console.log(date)

    return (
      <p key={key}>
      Judul : <b>{data.title}</b>
      <br/>
      Jadwal Siaran Langsung : <b><Moment format="D MMM YYYY" local >{data.live_schedule}</Moment> </b>
      <br/>
      Jam :   <b><Moment format="hh:mm A" local >{date}</Moment></b>
      <br/>
      <i>Link Vidio</i> : <a href={'https://www.youtube.com/watch?v='+data.yt_video_key}>{'https://www.youtube.com/watch?v='+data.yt_video_key}</a>
    </p>
    )
    
    
  }


  live(data, key)
  {

   var date = new Date(data.live_start).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }).replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
  //  var date = new Date(date).get
    console.log(date)
      return (
        <p key={key}>
        Judul : {data.title}
        <br/>
        Mulai <i>Live</i> : <Moment format="D MMM YYYY" local >{data.live_start}</Moment> 
        <br/>
        Jam :   <Moment format="hh:mm A" local >{date}</Moment>
        <br/>
        <i>Link Vidio</i> : <a href={'https://www.youtube.com/watch?v='+data.yt_video_key}>{'https://www.youtube.com/watch?v='+data.yt_video_key}</a>
      </p>
      )
   
  }

  render() {

    const {isLoading } = this.state;
 
    if (isLoading) {
      return (
        <React.Fragment>
        {/* LIVE SCHEDULE */}
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <CardTitle>Jadwal / <i>Live</i> <span><RiLiveLine></RiLiveLine></span> </CardTitle>
              <CardText>
                Tunggu Sebentar.....
              </CardText>
              <CardText>
                {/* <small className="text-muted">-</small> */}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </React.Fragment>
      )
    }

    var date = Date.now();


    return(
      <React.Fragment>
      {/* LIVE SCHEDULE */}
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle>Jadwal / <i>Live</i> <span><RiLiveLine></RiLiveLine></span> </CardTitle>
            <CardText>
              <h5 style={{color: 'white'}} ><i>Live Upcoming</i></h5>
              {this.state.data_up_check ? <div>Saat Ini Tidak Ada Jadwal <i>Live</i></div>:  this.state.data_up.map(this.live_upcoming)}


              <br/>
              <h5 style={{color: 'white'}}><i>Live</i></h5>
              {this.state.data_live_check ?  <div>Saat Ini Sedang Tidak <i>Live</i></div> :  this.state.data_live.map(this.live)}

            </CardText>
            <CardText>
            {/* <Moment format="D MMM YYYY" local > </Moment>  */}
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
    </React.Fragment>
    )
  }


}

export default Jadwal;
