/*--------------------------------------------------------------------------------*/
/*                                  starter                                    */
/*--------------------------------------------------------------------------------*/
import FirstDashboard from '../views/starter/starter.jsx';
/*--------------------------------------------------------------------------------*/
/*                           Ui-components Dropdown                               */
/*--------------------------------------------------------------------------------*/
/* INDONESIA COMPONENT */ 
import Youtube from '../components/id/youtube.jsx';
import Twitter from '../components/id/twitter.jsx';
import Facebook from '../components/id/facebook.jsx';
import Tentang from '../components/id/tentang.jsx';
/* ENGLISH COMPONENT */ 
import ENYoutube from '../components/en/youtube.jsx';
import ENTwitter  from '../components/en/twitter.jsx';
import ENFacebook  from '../components/en/facebook.jsx';
import ENAbout  from '../components/en/about.jsx';
/* JAPANESE COMPONENT */ 
// import JPYoutube  from '../components/id/youtube.jsx';
// import JPTwitter  from '../components/id/youtube.jsx';
// import JPFacebook  from '../components/id/youtube.jsx';
// import JPTentang  from '../components/id/youtube.jsx';

var ThemeRoutes = [
  // Indonesia
  {
    path: '/id/beranda',
    name: 'Beranda',
    icon: 'mdi mdi-adjust',
    component: FirstDashboard
  },
  {
    path: '/id/youtube',
    name: 'Youtube',
    icon: 'mdi mdi-youtube-play',
    component: Youtube
  },
  {
    path: '/id/twitter',
    name: 'Twitter',
    icon: 'mdi mdi-twitter',
    component: Twitter
  },
  {
    path: '/id/facebook',
    name: 'Facebook',
    icon: 'mdi mdi-facebook',
    component: Facebook
  },
  {
    path: '/id/tentang',
    name: 'Tentang',
    icon: 'mdi mdi-code-tags',
    component: Tentang
  },
 
  // REDIRECT
  {
    path: '/',
    pathTo: '/id/beranda',
    name: '',
    redirect: true
  },
  {
    path: '/id',
    pathTo: '/id/beranda',
    name: '',
    redirect: true
  },
];
export default ThemeRoutes;
