export const voice = [
    {
      "group_name": "Clip",
      "group_title": "Klip ",
      "voice_list": [
        {
            "name": "Evil Laugh",
            "path": "ketawa-jahat.mp3"
        },
        {
            "name": "Eat !!!",
            "path": "makan-dong.mp3"
        }
          
      ]
    },
    {
      "group_name": "DLC",
      "group_title": "DLC ",
      "voice_list": [
        {
            "name": "Yandere",
            "path": "yandere.mp3"
        },
          
      ]
    },
    ]