import React from "react";
import { Card, CardText, CardBody, CardTitle, Row, Col } from "reactstrap";
import marked from "marked";
import Floating from '../home/floating'
class youtube extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = { markdown: null }
      }

  componentDidMount() {
    const readmePath = require("../../assets/readme/en.md");

    fetch(readmePath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: marked(text)
        })
      })
  }

  render() {
    const { markdown } = this.state;

    return (
      <React.Fragment>
        <Row>
        <Col sm="12">
          <Card>
            <CardBody>
                  
            <section>
            <article dangerouslySetInnerHTML={{__html: markdown}}></article>
            </section>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
    )
  }


}

export default youtube;
