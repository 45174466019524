import React from "react";
import { Card, CardText, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import { RiLiveLine } from "react-icons/ri";
import { voice } from '../../voice/en'
import MultiPlayer from '../../multiplayer'
import Floating from './floating'
import EnJadwal from '../jadwal/en'

class Home extends React.Component {


  componentDidMount() {

  }

  render() {


    return(
      <React.Fragment>
      {/* LIVE SCHEDULE */}
      <EnJadwal />
    {/* KLIP KONTENT */}
    
        {voice.map((data, key) => {
          // console.log(data.voice_list)
          return (
            <Row key={key}>
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle key={key}>{data['group_name']}</CardTitle>
                  <CardText>
                  <MultiPlayer key={key} urls={data.voice_list}/> 
                  </CardText>
                  <CardText>
                    <small className="text-muted"></small>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          );
        })}




    
     {/* floating action */}
    {/* <Floating /> */}

    </React.Fragment>


    )
  }


}

export default Home;
