
/*--------------------------------------------------------------------------------*/
/*                                  starter                                    */
/*--------------------------------------------------------------------------------*/
import FirstDashboard from '../views/starter/stater_en.jsx';
/*--------------------------------------------------------------------------------*/
/*                           Ui-components Dropdown                               */
/*--------------------------------------------------------------------------------*/
/* ENGLISH COMPONENT */ 
import ENYoutube from '../components/en/youtube.jsx';
import ENTwitter  from '../components/en/twitter.jsx';
import ENFacebook  from '../components/en/facebook.jsx';
import ENAbout  from '../components/en/about.jsx';

var ThemeRoutes = [
{
    path: '/en/home',
    name: 'Home',
    icon: 'mdi mdi-adjust',
    component: FirstDashboard
  },
  {
    path: '/en/youtube',
    name: 'Youtube',
    icon: 'mdi mdi-youtube-play',
    component: ENYoutube
  },
  {
    path: '/en/twitter',
    name: 'Twitter',
    icon: 'mdi mdi-twitter',
    component: ENTwitter
  },
  {
    path: '/en/facebook',
    name: 'Facebook',
    icon: 'mdi mdi-facebook',
    component: ENFacebook
  },
  {
    path: '/en/about',
    name: 'About',
    icon: 'mdi mdi-code-tags',
    component: ENAbout
  },
    // REDIRECT
    {
        path: '/',
        pathTo: '/en/home',
        name: '',
        redirect: true
      },
      {
        path: '/en',
        pathTo: '/en/home',
        name: '',
        redirect: true
      },
];
export default ThemeRoutes;
