import React from 'react';
import ReactDOM from 'react-dom';
//import { createBrowserHistory } from 'history';
import indexRoutes from './routes/index.jsx';
import { Route, Switch } from 'react-router-dom';
import { HashRouter } from 'react-router-dom'
//

//
import './assets/scss/dark.css';

//const hist = createBrowserHistory();
console.info("%c🎨IOFI🎨", "color: white; font-style: bold; font-size: 72px;");
console.info("%cTwitter Airani Iofifteen : https://twitter.com/airaniiofifteen","color: white; font-size: 12px;")
console.info("%cYoutube : https://www.youtube.com/channel/UCAoy6rzhSf4ydcYjJw3WoVg","color: white; font-size: 12px;")
console.info("%c===========================================","color: white; font-size: 12px;")
console.info("%cMy Twitter : https://twitter.com/karuzuru","color: white; font-size: 12px;")
ReactDOM.render(

    <HashRouter>
        <Switch>
            {indexRoutes.map((prop, key) => {
                return <Route path={prop.path} key={key} component={prop.component} />;
            })}
        </Switch>
        
    </HashRouter>
    , document.getElementById('root')); 
