import Fulllayout from "../layouts/fulllayout.jsx";
import Fulllayout_En from "../layouts/fullayout_en";

var indexRoutes = [
    { 
        path: "/en/", 
        component: Fulllayout_En
    },
    { 
        path: "/id/", 
        component: Fulllayout
    },
    { 
        path: "/", 
        component: Fulllayout
    },
];

export default indexRoutes;
