import React from "react";
import { Card, CardText, CardBody, CardTitle, Row, Col } from "reactstrap";


class twitter extends React.Component {
  componentDidMount() {
    window.location.replace(
      "https://twitter.com/airaniiofifteen"
    );
  }

  render() {
    return(
      <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <CardTitle>Redirect...</CardTitle>
            <CardText>
            </CardText>
            <CardText>
              {/* <small className="text-muted">Last updated 3 mins ago</small> */}
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
    )
  }


}

export default twitter;
