import React from "react";
import { Row, Col } from "reactstrap";
import Home from "../../components/home/home";

const Starter = () => {
  return (
    <div>
      <Home></Home>
    </div>
  );
};

export default Starter;
